//  导入Vue
import Vue from 'vue'
// 导入Axios模块
import Axios from 'axios'

// 将axios挂载到Vue的原型对象上
Vue.prototype.$http = Axios

// 封装axios的全局配置函数

Axios.create.timeout = 3000
Axios.create.baseURL = 'https://xianapi.bjfuture.cn/dfck/blocklyCMS/iotcp' //  设置基本请求路径
let url = 'https://xianapi.bjfuture.cn/dfck/blocklyCMS/iotcp'

// 请求拦截器（对请求数据配置做统一处理）
Axios.interceptors.request.use(
  config => {
    let token = window.sessionStorage.getItem('csrfToken')
    // config.headers.set(
    //   'Authorization',
    //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODQ0NjQxNDQsImlkIjoiMSIsIm9yaWdfaWF0IjoxNjg0Mzc3NzQ0fQ.71i4Bcb96gZZnWzSRREPbi8Et4W3-gx7yk7IIDGvRCo',
    // )
    if (config.method == 'post') {
      config.baseURL = url
      // config.data = { ...config.data }  // 该项会导致阿里云OSS上传报错（跨域）
      config.withCredentials = false
      if (token) {
        config.headers.set('Authorization', `Bearer ${token}`)
      }
    } else if (config.method == 'get') {
      config.baseURL = url
      if (token) {
        config.headers.set('Authorization', `Bearer ${token}`)
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

// 响应拦截器（对成功请求到数据做处理）
Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status == 500) {
      return Promise.reject('true')
    }
    return Promise.reject(error)
  },
)

/**
 *
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios({
      url: url,
      method: 'GET',
      params: params,
      transformRequest: [
        function(data) {
          let ret = ''
          for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        },
      ],
    })
      .then(res => {
        if (res.data.code == 1001) {
          this.$Message.warning('登录超时,' + res.data.message)
          this.$router.push({ path: '/' })
          return
        }
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 *
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    Axios({
      url: url,
      method: 'POST',
      data: data,
      // transformRequest: [
      //   function(data) {
      //     let ret = ''
      //     for (let it in data) {
      //       ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      //     }
      //     return ret
      //   },
      // ],
    })
      .then(res => {
        if (res.data.code == 1001) {
          this.$Message.warning('用户超时' + res.data.message)
          this.$router.push({ path: '/' })
          return
        }
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
