import Vue from 'vue'
import Vuex from 'vuex'
import { blue_light } from '../assets/style/blue_light'

Vue.use(Vuex)
const state = {
  themeColor: blue_light,
  loginStatus: false,
  mqttList: [
    // {
    //   protocol: 'ws',
    //   host: 'xianapi.bjfuture.cn',
    //   port: 1884,
    //   endpoint: '/mqtt',
    //   clean: true,
    //   connectTimeout: 30 * 1000, // ms
    //   reconnectPeriod: 4000, // ms
    //   clientId: 'aieducation_789',
    //   username: 'tianqi_test',
    //   password: 'tianqi_test',
    //   connecting: false,
    //   client: {
    //     connected: false,
    //   },
    //   topicList: [
    //     {
    //       clientId: 'aieducation_789',
    //       topicName: "tianqi11",
    //       qos: 0,
    //       subscribeSuccess: false,
    //     },
    //     {
    //       clientId: 'aieducation_789',
    //       topicName: "tianqi12",
    //       qos: 0,
    //       subscribeSuccess: false,
    //     }
    //   ],
    //   thirdparty: true
    // },
    // {
    //   protocol: 'ws',
    //   host: 'mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com',
    //   port: 80,
    //   endpoint: '/mqtt',
    //   clean: true,
    //   connectTimeout: 30 * 1000, // ms
    //   reconnectPeriod: 4000, // ms
    //   clientId: 'GID_TIANQI@@@xxxxx',
    //   username: '123',
    //   password: '123',
    //   connecting: false,
    //   client: {
    //     connected: false,
    //   },
    //   topicList: [
    //     {
    //       clientId: 'aieducation_789',
    //       topicName: "tianqi21",
    //       qos: 0,
    //       subscribeSuccess: false,
    //     },
    //     {
    //       clientId: 'aieducation_789',
    //       topicName: "tianqi22",
    //       qos: 0,
    //       subscribeSuccess: false,
    //     }
    //   ],
    //   thirdparty: false
    // },
  ],
  nowGroup: '',
  nowMqttIndex: 0,
  nowTopic: '',
  lefbarDisplay: false,
  mqttMessage: [
    // {
    //   clientId: 'GID--TQ@@@2-h213X',
    //   key: '光线传感器',
    //   port: 'P1',
    //   qos: 0,
    //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
    //   time: '2023-05-09 10:29:14',
    //   topic: 'group/Felix1/1',
    //   type: 'send',
    //   value: '111',
    // },
    // {
    //   clientId: 'GID--TQ@@@2-h213X',
    //   key: '光线传感器',
    //   port: 'P1',
    //   qos: 0,
    //   server: 'ws://mqtt-cn-20p35tzwv02.mqtt.aliyuncs.com:80',
    //   time: '2023-05-09 10:29:14',
    //   topic: 'group/Felix1/1',
    //   type: 'receive',
    //   value: '222',
    // },
  ],
}
const mutations = {
  updateThemeColor(state, data) {
    state.themeColor = data
  },
  updateLoginStatus(state, data) {
    state.loginStatus = data
  },
  updateMqttList(state, data) {
    state.mqttList = data
  },
  setMqttList(state, data) {
    // Vue.set(state.mqttList, 0, 233)

    // state.mqttList.splice(1, 1, state.mqttList[1])

    state.mqttList.push(data)
  },
  delMqttList(state, num) {
    state.mqttList.splice(num, 1)
    state.nowMqttIndex = 0
  },
  clearMqttList(state) {
    state.mqttList = []
  },
  updateNowGroup(state, data) {
    state.nowGroup = data
  },
  updateMqttIndex(state, data) {
    state.nowMqttIndex = data
  },
  updateNowTopic(state, data) {
    state.nowTopic = data
  },
  setTopicList(state, data) {
    state.mqttList[state.nowMqttIndex].topicList.push(data)
  },
  delTopicList(state, data) {
    state.mqttList[state.nowMqttIndex].topicList.splice(data, 1)
  },
  clearTopicList(state, data) {
    state.mqttList[state.nowMqttIndex].topicList.splice(0, state.mqttList[state.nowMqttIndex].topicList.length)
  },
  updateLefbarDisplay(state, data) {
    state.lefbarDisplay = data
  },
  updateMqttMessage(state, data) {
    state.mqttMessage = data
  },
  setMqttMessage(state, data) {
    state.mqttMessage.push(data)
  },
  delMqttMessage(state, num) {
    state.mqttMessage.splice(num, 1)
  },
}
const store = new Vuex.Store({
  state,
  mutations,
})

export default store
